import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import {
  AssignToUserViewModel,
  LookupClient,
  MyAccountAccountsClient,
  PaymentLinkClient,
  PaymentLinkFilterBy,
  PaymentLinkFilterByFilterBy,
  PaymentLinkSortBy,
  PaymentLinkSortBySortBy,
  PaymentLinkViewModel,
  UsersClient,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { ICommonPaginatorEvent } from "src/app/shared/widgets/paginator/paginator.component";

@Component({
  selector: "app-my-account-user-link-list",
  templateUrl: "./link-list.component.html",
  styleUrls: ["./link-list.component.scss"],
})
export class LinkListComponent implements OnInit {

  private subscription: Subscription = new Subscription();

  pageIndex: number;
  pageSize: number;
  sortBy: PaymentLinkSortBySortBy[] = [];
  filterBy: PaymentLinkFilterByFilterBy[] = [];

  isLoading: boolean;
  isInitialized: boolean;

  records: PaymentLinkViewModel[] = [];
  totalRecords: number;

  filterFormGroup = new FormGroup({
    id: new FormControl(null),
    accountNumber: new FormControl(null),
    assigneeId: new FormControl(null)
  });

  isAssigneeListLoading: boolean;

  assigneeList: AssignToUserViewModel[];
  hasValues = false;

  constructor(
    private myAccountAccountsClient: MyAccountAccountsClient,
    private paymentLinkClient: PaymentLinkClient,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private userClient: UsersClient,
  ) { }

  ngOnInit() {
    this.pageIndex = 1;
    this.pageSize = 10;
    this.getAssigneeList();
  }

  getAssigneeList() {

    this.isAssigneeListLoading = true;
    this.subscription.add(
      this.userClient.getAssignableUsers().subscribe(response => {
        this.assigneeList = response.result;
        this.isAssigneeListLoading = false;
      },
        err => {
          this.snackBarService.ShowError('Fetching Assignee List Error: ' + err);
        })
    );
  }

  getAssigneeId = (assignee: AssignToUserViewModel) => assignee.id;
  getAssigneeName = (assignee: AssignToUserViewModel) => assignee.name === null ? '' : assignee.name;

  validateSearch() {
    if (this.filterFormGroup.value.accountNumber && this.filterFormGroup.value.accountNumber != 0) {
      return true;
    }
    if (this.filterFormGroup.value.assigneeId && this.filterFormGroup.value.assigneeId != "") {
      return true;
    }
    return false;
  }


  loadPaymentLinks() {
    this.isLoading = true;
    var isValidated = this.validateSearch();
    if (!isValidated) {
      this.isLoading = false;
      this.snackBarService.ShowError("Please enter a value for either Account Number or Created By.");
      return;
    }
    this.updateFilters();
    this.subscription.add(
      this.paymentLinkClient.paymentLinkListSearch(
        this.pageIndex, this.pageSize, this.sortBy,
        this.filterBy.length == 0 ? undefined : this.filterBy
      ).subscribe(
        (result) => {
          this.isLoading = false;
          this.hasValues = true;
          this.records = result.records;
          this.totalRecords = result.totals[0].value;
          setTimeout(() => {
            this.isInitialized = true;
          }, 2000);
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(error);
        }
      )
    );
  }

  updateFilters() {
    // Default Sort By
    this.sortBy = [
      new PaymentLinkSortBySortBy({
        property: PaymentLinkSortBy.Id,
        descendingOrder: false,
      }),
    ];

    this.filterBy = [];
    // Id
    if (
      this.filterFormGroup.value.accountNumber &&
      this.filterFormGroup.value.accountNumber != 0
    ) {
      this.filterBy.push(
        new PaymentLinkFilterByFilterBy({
          property: PaymentLinkFilterBy.AccountNumber,
          equal: this.filterFormGroup.value.accountNumber
        })
      );
    }
    // Created By User
    if (
      this.filterFormGroup.value.assigneeId &&
      this.filterFormGroup.value.assigneeId != "" &&
      this.filterFormGroup.value.assigneeId != null
    ) {
      this.filterBy.push(
        new PaymentLinkFilterByFilterBy({
          property: PaymentLinkFilterBy.CreatedBy,
          equal: this.filterFormGroup.value.assigneeId
        })
      );
    }

  }

  onClearFiltersClicked() {
    this.pageIndex = 1;
    this.filterFormGroup.patchValue({
      id: null,
      assigneeId: null,
    });
    this.loadPaymentLinks();
  }

  onFilterGroupsClicked() {
    this.pageIndex = 1;
    this.loadPaymentLinks();
  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }
      this.pageSize = e.pageSize;
      this.loadPaymentLinks();
    }
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
