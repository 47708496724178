import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import {
  ReportsClient,
  EventLogFilterByFilterBy,
  EventLogSortBySortBy,
  EventLogViewModel,
  EventLogSortBy,
  EventLogFilterBy,
  EventType,
  EventAction,
  EventOrigin
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ICommonPaginatorEvent } from "src/app/shared/widgets/paginator/paginator.component";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { IResendEmailDialogInputData, ResendEmailDialogComponent } from "./resend-email-dialog/resend-email-dialog.component";

@Component({
  selector: "app-events-page",
  templateUrl: "./events-page.component.html",
  styleUrls: ["./events-page.component.scss"],
})
export class EventsPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  isResultLoading: boolean;
  hasValues: boolean;
  totalRecords: number;

  @Input() accountNumber: string;

  toolDescription = "This tool is meant to find errors related to an account. \n It shows all actions/events that have occurred for a specific account number during a specific time period. \n By default it shows the results in descending order, but this can be changed."

  selectedIds: string[] = [];
  private isInitialized: boolean;

  selectedReport: string;

  dateFormGroup = new FormGroup({
    startDate: new FormControl(null),
    endDate: new FormControl(null),
    orderBy: new FormControl(null)
  });

  filterFormGroup = new FormGroup({
    accountNumber: new FormControl(null),
    selectedRequestType: new FormControl(null),
    selectedEventOrigin: new FormControl(null)
  });

  requestType= new FormControl([]);

  events: any[];
  isLoading: boolean;

  public pageIndex: number;
  public pageSize: number;
  filterBy: EventLogFilterByFilterBy[] = [];
  sortBy: EventLogSortBySortBy[] = [];
  sortControl = new FormControl('desc'); // Default to "Date: New to Old"
  requests: EventLogViewModel[];

  eventActions = Object.values(EventAction);
  eventTypes = Object.values(EventType);
  eventOrigins = Object.values(EventOrigin);

  constructor(
    private reportClient: ReportsClient,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
  ) {}

  ngOnInit() {
    this.pageIndex = 1;
    this.pageSize = 10;
    this.initializeSortBy();
    this.filterRequests();
  }

  initializeSortBy(): void {
    const sortByDaysOld = new EventLogSortBySortBy({
      property: EventLogSortBy.OccurredAt,
      descendingOrder: this.sortControl.value === 'desc',
    });

    this.sortBy.push(sortByDaysOld);
  }

  onSortChange(sortOrder: string): void {
    this.sortBy = []; // Clear the existing sortBy array

    const sortByDaysOld = new EventLogSortBySortBy({
      property: EventLogSortBy.OccurredAt,
      descendingOrder: sortOrder === 'desc',
    });

    this.sortBy.push(sortByDaysOld);
    this.filterRequests();
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.toLowerCase().indexOf(term) > -1;
  }

  resendEmail(event: EventLogViewModel)
  {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = false;
    dialogConfig.width = "600px";
    dialogConfig.maxHeight = "800px";

    const data: IResendEmailDialogInputData = {
      eventId: event.id
    };

    dialogConfig.data = data;
    this.subscription.add(
      this.dialog
        .open(ResendEmailDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((response) => {
          if (response) {
            this.snackBarService.ShowSuccess("Email Confirmation Resent Successfully");
          }
        })
    );
  }
  canResendEmail(event: EventLogViewModel)
  {
    return event.eventAction == EventAction.SuccessfulPayment;
  }

  isAllowedToCancel(event: EventLogViewModel)
  {
    return event.eventAction == EventAction.FuturePaymentScheduled;
  }

  cancelFuturePayment(event: EventLogViewModel)
  {
    this.isLoading = true;
    this.subscription.add(
      this.reportClient
        .cancelFuturePayment(
          event
        )
        .subscribe(
          (result) => {
            this.isLoading = false;
            this.snackBarService.ShowSuccess("Payment Cancelled Successfully");
          },
          (error) => {
            console.error(error);
            this.isLoading = false;
            this.snackBarService.ShowError(error);
          }
        )
    );
    
  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }

      this.pageSize = e.pageSize;
      this.filterRequests();
    }
  }

  loadEventTypes() {
    this.isLoading = true;

    this.subscription.add(
      this.reportClient
        .eventLogListSearch(
          this.pageIndex,
          this.pageSize,
          this.sortBy,
          this.filterBy.length == 0 ? undefined : this.filterBy
        )
        .subscribe(
          (result) => {
            this.requests = result.records;
            this.isLoading = false;
            this.hasValues = true;
            this.totalRecords = result.totals[0].value;

            setTimeout(() => {
              this.isInitialized = true;
            }, 2000);
          },
          (error) => {
            console.error(error);
            this.isLoading = false;
            this.snackBarService.ShowError(error);
          }
        )
    );
  }

  filterRequests() {
    this.filterBy = [];

    const filterByStatus = new EventLogFilterByFilterBy({
        property: EventLogFilterBy.AccountNumber,
        equal: this.accountNumber
    });
    this.filterBy.push(filterByStatus);

    const startDate = this.dateFormGroup.get('startDate').value;
    const endDate = this.dateFormGroup.get('endDate').value;

    if (startDate) {
        const filterByStartDate = new EventLogFilterByFilterBy({
            property: EventLogFilterBy.StartDate,
            equal: startDate
        });
        this.filterBy.push(filterByStartDate);
    }

    if (endDate) {
        if (startDate && endDate < startDate) {
            this.snackBarService.ShowError('End date must be after start date');
            return;
        }
        const filterByEndDate = new EventLogFilterByFilterBy({
            property: EventLogFilterBy.EndDate,
            equal: endDate
        });
        this.filterBy.push(filterByEndDate);
    }

    const requestTypes = this.selectedIds;
    if (requestTypes && requestTypes.length > 0) {
        const filterByRequestType = new EventLogFilterByFilterBy({
            property: EventLogFilterBy.EventAction,
            in: requestTypes.join(',')
        });
        this.filterBy.push(filterByRequestType);
    }

    if (this.filterFormGroup.get('selectedRequestType').value) {
        const filterByRequestType = new EventLogFilterByFilterBy({
            property: EventLogFilterBy.EventType,
            equal: this.filterFormGroup.get('selectedRequestType').value
        });
        this.filterBy.push(filterByRequestType);
    }

    if (this.filterFormGroup.get('selectedEventOrigin').value) {
      const filterByRequestType = new EventLogFilterByFilterBy({
          property: EventLogFilterBy.EventOrigin,
          equal: this.filterFormGroup.get('selectedEventOrigin').value
      });
      this.filterBy.push(filterByRequestType);
  }

    this.loadEventTypes();
  }

  onClearFiltersClicked() {
    this.filterFormGroup.patchValue({
        accountNumber: null,
        selectedRequestType: null,
        selectedEventOrigin: null
    });
    this.sortControl.setValue('desc'); // Reset to default
    this.sortBy = []; // Clear the existing sortBy array

    const sortByDaysOld = new EventLogSortBySortBy({
        property: EventLogSortBy.OccurredAt,
        descendingOrder: true,
    });

    this.sortBy.push(sortByDaysOld);
    this.dateFormGroup.patchValue({
        startDate: null,
        endDate: null
    });
    this.selectedIds = [];
    this.filterRequests();
  }

  getUserDisplay(event: any): string {
    if (event.csrPortalUserUniqueId) {
      return `${event.csrPortalUserEmail} (CSR)`;
    } else if (event.impersonatedUserUniqueId) {
      return `${event.myAccountUserEmail} impersonating ${event.impersonatedUserEmail}`;
    } else if (event.myAccountUserUniqueId) {
      return `${event.myAccountUserEmail}`;
    } else {
      return 'Guest';
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}