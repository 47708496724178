import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "./auth.service";
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private snackBarService: SnackBarService, private translateService: TranslateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.data.systemAdmin) {
      if (!this.authService.isSystemAdmin) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    }
    if (route.data.isAllowedToCreateNotification) {
      if (!this.authService.isAllowedToCreateNotification) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    }
    if (route.data.isAllowedToCreateFacility) {
      if (!this.authService.isAllowedToCreateFacility) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    }
    if (route.data.isAllowedToCreateBusinessUnit) {
      if (!this.authService.isAllowedToCreateBusinessUnit) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    }
    if (route.data.isAllowedToViewRequestStats) {
      if (!this.authService.isAllowedToViewRequestStats) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    }
    if (route.data.isAllowedToViewReports) {
      if (!this.authService.isAllowedToViewReports) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    }
    if (route.data.isAllowedToUseRecordSync) {
      if (!this.authService.isAllowedToUseRecordSync) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    }

    if (route.data.isAllowedToViewBusinessUnits) {
      if (!this.authService.isAllowedToViewBusinessUnits) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    }    

    if (route.data.isAllowedToViewFacilities) {
      if (!this.authService.isAllowedToViewFacilities) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToViewAllUsers) {
      if (!this.authService.isAllowedToViewAllUsers) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToCreateUser) {
      if (!this.authService.isAllowedToCreateUser) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToViewNotifications) {
      if (!this.authService.isAllowedToViewNotifications) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToTransferOwnership) {
      if (!this.authService.isAllowedToTransferOwnership) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToMassTransferOwnership) {
      if (!this.authService.isAllowedToMassTransferOwnership) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    }  
    if (route.data.isAllowedToDeleteFusionAutoPay) {
      if (!this.authService.isAllowedToDeleteFusionAutoPay) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    }
    if (route.data.isAllowedToUsePayMyBillValidation) {
      if (!this.authService.isAllowedToUsePayMyBillValidation) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToViewRequests) {
      if (!this.authService.isAllowedToViewRequests) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 

    if (route.data.isAllowedToCreateMyAccountUser) {
      if (!this.authService.isAllowedToCreateMyAccountUser) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToViewRequests) {
      if (!this.authService.isAllowedToViewRequests) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToCreateGroups) {
      if (!this.authService.isAllowedToCreateGroups) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToViewGroups) {
      if (!this.authService.isAllowedToViewGroups) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToCreateManagedAutoPayMyAccountUser) {
      if (!this.authService.isAllowedToCreateManagedAutoPayMyAccountUser) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToUseAccountLookup) {
      if (!this.authService.isAllowedToUseAccountLookup) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToDeleteEbilling) {
      if (!this.authService.isAllowedToDeleteEbilling) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToDeleteAzureProfile) {
      if (!this.authService.isAllowedToDeleteAzureProfile) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToUseCustomerFinder) {
      if (!this.authService.isAllowedToUseCustomerFinder) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToViewErrorHandling) {
      if (!this.authService.isAllowedToViewErrorHandling) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 

    if (route.data.isAllowedToDeleteMyAccountProfile) {
      if (!this.authService.isAllowedToDeleteMyAccountProfile) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 

    if (route.data.isAllowedToUseEmailLookup) {
      if (!this.authService.isAllowedToUseEmailLookup) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToViewEcommerceAreas) {
      if (!this.authService.isAllowedToViewEcommerceAreas) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToViewEcommerceBlacklist) {
      if (!this.authService.isAllowedToViewEcommerceBlacklist) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToViewEcommerceFees) {
      if (!this.authService.isAllowedToViewEcommerceFees) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToViewPromoCodes) {
      if (!this.authService.isAllowedToViewPromoCodes) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToViewServiceCodes) {
      if (!this.authService.isAllowedToViewServiceCodes) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToViewServiceFrequencies) {
      if (!this.authService.isAllowedToViewServiceFrequencies) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToViewBillingCycles) {
      if (!this.authService.isAllowedToViewBillingCycles) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToViewServiceTypes) {
      if (!this.authService.isAllowedToViewServiceTypes) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToEditEcommerceAreas) {
      if (!this.authService.isAllowedToEditEcommerceAreas) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToEditEcommerceBlacklist) {
      if (!this.authService.isAllowedToEditEcommerceBlacklist) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToEditEcommerceFees) {
      if (!this.authService.isAllowedToEditEcommerceFees) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToEditPromoCodes) {
      if (!this.authService.isAllowedToEditPromoCodes) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToEditServiceCodes) {
      if (!this.authService.isAllowedToEditServiceCodes) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToEditServiceFrequencies) {
      if (!this.authService.isAllowedToEditServiceFrequencies) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToEditBillingCycles) {
      if (!this.authService.isAllowedToEditBillingCycles) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToEditServiceTypes) {
      if (!this.authService.isAllowedToEditServiceTypes) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToDeleteEcommerceAreas) {
      if (!this.authService.isAllowedToDeleteEcommerceAreas) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToDeleteEcommerceBlacklist) {
      if (!this.authService.isAllowedToDeleteEcommerceBlacklist) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToDeleteEcommerceFees) {
      if (!this.authService.isAllowedToDeleteEcommerceFees) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToDeletePromoCodes) {
      if (!this.authService.isAllowedToDeletePromoCodes) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToDeleteServiceCodes) {
      if (!this.authService.isAllowedToDeleteServiceCodes) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToDeleteServiceFrequencies) {
      if (!this.authService.isAllowedToDeleteServiceFrequencies) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToDeleteBillingCycles) {
      if (!this.authService.isAllowedToDeleteBillingCycles) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToDeleteServiceTypes) {
      if (!this.authService.isAllowedToDeleteServiceTypes) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToCreateEcommerceAreas) {
      if (!this.authService.isAllowedToCreateEcommerceAreas) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToCreateEcommerceBlacklist) {
      if (!this.authService.isAllowedToCreateEcommerceBlacklist) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToCreateEcommerceFees) {
      if (!this.authService.isAllowedToCreateEcommerceFees) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToCreatePromoCodes) {
      if (!this.authService.isAllowedToCreatePromoCodes) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToCreateServiceCodes) {
      if (!this.authService.isAllowedToCreateServiceCodes) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToCreateServiceFrequencies) {
      if (!this.authService.isAllowedToCreateServiceFrequencies) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToCreateBillingCycles) {
      if (!this.authService.isAllowedToCreateBillingCycles) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    } 
    if (route.data.isAllowedToCreateServiceTypes) {
      if (!this.authService.isAllowedToCreateServiceTypes) {
        this.router.navigate(["/"]);
        this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
        return false;
      }
    }

    return true;
  }
}
