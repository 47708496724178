import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {
  RoleViewModel,
  UpdateUserCommand,
  UserBusinessUnitViewModel,
  BusinessUnitViewModel,
  LookupClient,
  UsersClient,
  PermissionsClient,
  GroupDetailsViewModel,
  GroupBusinessUnitViewModel,
  PermissionsViewModel,
  PermissionGroupDetailsViewModel,
  GroupPermissionViewModel,
  PermissionGroupUserViewModel,
  UserPermissionViewModel,
  UserPermissionsViewModel,
} from 'src/app/apis/customer-care-api.generated';
import {SnackBarService} from 'src/app/shared/widgets/snack-bar/snack-bar.service';
import {AuthService} from 'src/app/shared/auth/auth.service';


@Component({
  selector: 'app-user-edit-page',
  templateUrl: './user-edit-page.component.html',
  styleUrls: ['./user-edit-page.component.scss'],
})
export class UserEditPageComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  id: number;
  changesMade: boolean;
  savedChanges: boolean;

  roles: RoleViewModel[];

  totalBusinessUnits: BusinessUnitViewModel[];
  totalGroups: GroupDetailsViewModel[];

  isLoading = false;
  groupsLoading: boolean;
  individualBUsLoading: boolean;
  groupBUsLoading: boolean;
  userDetailsLoading: boolean;


  groupBUCodes: GroupBusinessUnitViewModel[];
  individualBUCodes: UserBusinessUnitViewModel[];

  userFormGroup: FormGroup;
  userPermissionFormGroup: FormGroup;

  // get all Permission Groups for dropdown (groupName and groupId) and for selected boxes
  allPermissionGroupsLoading: boolean;
  allPermissionGroups: PermissionGroupDetailsViewModel[];

  // get all permissions
  allPermissionsLoading: boolean;
  filledValues = false;
  permissionValues: boolean[] = [];
  allPermissions: PermissionsViewModel[];

  isAllowedToEditPermissions: boolean;


  // get the user's permissions
  usersCurrentPermissionsLoading: boolean;
  checkedPermissions: UserPermissionsViewModel[];

  // get the current user's permission groups
  usersCurrentPermissionGroupsLoading: boolean;
  usersCurrentPermissionGroups: PermissionGroupUserViewModel[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private usersClient: UsersClient,
    private permissionsClient: PermissionsClient,
    private lookupClient: LookupClient,
    private authService: AuthService,
    private snackBarService: SnackBarService,
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.subscription
      .add(
        this.authService.currentUser
          .subscribe(user => {
            if (user) {
              this.isAllowedToEditPermissions = user.isSystemAdmin;
            }
          })
      );

    this.userPermissionFormGroup = new FormGroup({
      userId: new FormControl(null),
      fullName: new FormControl(null),
      isSystemAdmin: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToDeleteFusionAutoPay: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToImpersonateInMyAccount: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreateNotification: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewRequestStats: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToEditGroups: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreateManagedAutoPayMyAccountUser: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isUserAllowedToSetStatusClosedNotAddressed: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreateBusinessUnit: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreateFacility: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToFilterByAssignee: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToUseRecordSync: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewRequests: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewErrorHandling: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewAllUsers: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToEditUsers: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreateUser: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewGroups: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreateGroups: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewBusinessUnits: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewFacilities: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToEditFacilities: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToDeleteFacilities: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreateMyAccountUser: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToUsePayMyBillValidation: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewNotifications: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToUseEmailLookup: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToTransferOwnership: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToMassTransferOwnership: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToUseAccountLookup: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToDeleteAzureProfile: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToDeleteEbilling: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToUseCustomerFinder: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToEditBusinessUnits: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToDeleteMyAccountProfile: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToEditPermissionGroups: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewReports: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreatePermissionGroups: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewAllPermissionGroups: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToUnlinkAccount: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewCSRBusinessUnitAccessReport: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewCustomerEmailsLinkedToAccountsReport: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewAccountsAutopayStatusReport: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewContactCorpSiteRequestTypeCountReport: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewOpenRequestsReport: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToUpdateBusinessUnitInvoicePresentment: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewEcommerceAreas: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewEcommerceBlacklist: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewEcommerceFees: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewPromoCodes: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewServiceCodes: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewServiceFrequencies: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewServiceTypes: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewBillingCycles: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToEditEcommerceAreas: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToEditEcommerceBlacklist: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToEditEcommerceFees: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToEditPromoCodes: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToEditServiceCodes: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToEditServiceFrequencies: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToEditServiceTypes: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToEditBillingCycles: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToDeleteEcommerceAreas: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToDeleteEcommerceBlacklist: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToDeleteEcommerceFees: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToDeletePromoCodes: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToDeleteServiceCodes: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToDeleteServiceFrequencies: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToDeleteServiceTypes: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToDeleteBillingCycles: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreateEcommerceAreas: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreateEcommerceBlacklist: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreateEcommerceFees: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreatePromoCodes: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreateServiceCodes: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreateServiceFrequencies: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreateServiceTypes: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToCreateBillingCycles: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToUseSecureCheckoutLinks: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      isAllowedToViewAccountEventLogs: new FormControl({value: false, disabled: !this.isAllowedToEditPermissions}),
      
    });

    this.userFormGroup = new FormGroup({
      userId: new FormControl(null),
      fullName: new FormControl(null),
      businessUnits: new FormArray([]),
      groupBusinessUnits: new FormArray([]),
      groups: new FormArray([]),
      permissions: new FormArray([])
    });

    this.id = this.activatedRoute.snapshot.params['userId'];
    this.loadUserGroups();
    this.loadUserGroupBUs();
    this.loadIndivdualGroupBUs();
    this.loadUserDetails();
    this.loadGroups();
    this.loadRoles();
    this.loadBusinessUnits();

    this.loadAllPermissionGroups();
    this.loadAllPermissions();
    this.loadUserPermissions();
    this.loadCurrentPermissionGroups();
    this.changesMade = false;
    this.savedChanges = false;
    this.updateLoading();
  }


  private updateLoading() {
    this.isLoading = this.groupsLoading === true || this.groupBUsLoading === true || this.individualBUsLoading === true || this.userDetailsLoading === true;
  }

  loadUserPermissions() {
    this.subscription.add(this.permissionsClient.getUserPermissions(this.id).subscribe(
      (response) => {
        const val = response.result;
        this.userPermissionFormGroup.patchValue({
          isSystemAdmin: val.isSystemAdmin,
          isAllowedToDeleteFusionAutoPay: val.isAllowedToDeleteFusionAutoPay,
          isAllowedToImpersonateInMyAccount: val.isAllowedToImpersonateInMyAccount,
          isAllowedToCreateNotification: val.isAllowedToCreateNotification,
          isAllowedToViewRequestStats: val.isAllowedToViewRequestStats,
          isAllowedToEditGroups: val.isAllowedToEditGroups,
          isUserAllowedToSetStatusClosedNotAddressed: val.isUserAllowedToSetStatusClosedNotAddressed,
          isAllowedToCreateManagedAutoPayMyAccountUser: val.isAllowedToCreateManagedAutoPayMyAccountUser,
          isAllowedToCreateBusinessUnit: val.isAllowedToCreateBusinessUnit,
          isAllowedToCreateFacility: val.isAllowedToCreateFacility,
          isAllowedToFilterByAssignee: val.isAllowedToFilterByAssignee,
          isAllowedToUseRecordSync: val.isAllowedToUseRecordSync,
          isAllowedToViewRequests: val.isAllowedToViewRequests,
          isAllowedToViewErrorHandling: val.isAllowedToViewErrorHandling,
          isAllowedToViewAllUsers: val.isAllowedToViewAllUsers,
          isAllowedToEditUsers: val.isAllowedToEditUsers,
          isAllowedToCreateUser: val.isAllowedToCreateUser,
          isAllowedToViewGroups: val.isAllowedToViewGroups,
          isAllowedToCreateGroups: val.isAllowedToCreateGroups,
          isAllowedToViewBusinessUnits: val.isAllowedToViewBusinessUnits,
          isAllowedToViewFacilities: val.isAllowedToViewFacilities,
          isAllowedToEditFacilities: val.isAllowedToEditFacilities,
          isAllowedToDeleteFacilities: val.isAllowedToDeleteFacilities,
          isAllowedToCreateMyAccountUser: val.isAllowedToCreateMyAccountUser,
          isAllowedToUsePayMyBillValidation: val.isAllowedToUsePayMyBillValidation,
          isAllowedToViewNotifications: val.isAllowedToViewNotifications,
          isAllowedToUseEmailLookup: val.isAllowedToUseEmailLookup,
          isAllowedToTransferOwnership: val.isAllowedToTransferOwnership,
          isAllowedToMassTransferOwnership: val.isAllowedToMassTransferOwnership,
          isAllowedToUseAccountLookup: val.isAllowedToUseAccountLookup,
          isAllowedToDeleteAzureProfile: val.isAllowedToDeleteAzureProfile,
          isAllowedToDeleteEbilling: val.isAllowedToDeleteEbilling,
          isAllowedToUseCustomerFinder: val.isAllowedToUseCustomerFinder,
          isAllowedToEditBusinessUnits: val.isAllowedToEditBusinessUnits,
          isAllowedToDeleteMyAccountProfile: val.isAllowedToDeleteMyAccountProfile,
          isAllowedToViewReports: val.isAllowedToViewReports,
          isAllowedToCreatePermissionGroups: val.isAllowedToCreatePermissionGroups,
          isAllowedToViewAllPermissionGroups: val.isAllowedToViewAllPermissionGroups,
          isAllowedToEditPermissionGroups: val.isAllowedToEditPermissionGroups,
          isAllowedToUnlinkAccount: val.isAllowedToUnlinkAccount,
          isAllowedToViewCSRBusinessUnitAccessReport: val.isAllowedToViewCSRBusinessUnitAccessReport,
          isAllowedToViewCustomerEmailsLinkedToAccountsReport: val.isAllowedToViewCustomerEmailsLinkedToAccountsReport,
          isAllowedToViewAccountsAutopayStatusReport: val.isAllowedToViewAccountsAutopayStatusReport,
          isAllowedToViewContactCorpSiteRequestTypeCountReport: val.isAllowedToViewContactCorpSiteRequestTypeCountReport,
          isAllowedToViewOpenRequestsReport: val.isAllowedToViewOpenRequestsReport,
          isAllowedToUpdateBusinessUnitInvoicePresentment: val.isAllowedToUpdateBusinessUnitInvoicePresentment,
          isAllowedToViewEcommerceAreas: val.isAllowedToViewEcommerceAreas,
          isAllowedToViewEcommerceBlacklist: val.isAllowedToViewEcommerceBlacklist,
          isAllowedToViewEcommerceFees: val.isAllowedToViewEcommerceFees,
          isAllowedToViewPromoCodes: val.isAllowedToViewPromoCodes,
          isAllowedToViewServiceCodes: val.isAllowedToViewServiceCodes,
          isAllowedToViewServiceFrequencies: val.isAllowedToViewServiceFrequencies,
          isAllowedToViewServiceTypes: val.isAllowedToViewServiceTypes,
          isAllowedToViewBillingCycles: val.isAllowedToViewBillingCycles,
          isAllowedToEditEcommerceAreas: val.isAllowedToEditEcommerceAreas,
          isAllowedToEditEcommerceBlacklist: val.isAllowedToEditEcommerceBlacklist,
          isAllowedToEditEcommerceFees: val.isAllowedToEditEcommerceFees,
          isAllowedToEditPromoCodes: val.isAllowedToEditPromoCodes,
          isAllowedToEditServiceCodes: val.isAllowedToEditServiceCodes,
          isAllowedToEditServiceFrequencies: val.isAllowedToEditServiceFrequencies,
          isAllowedToEditServiceTypes: val.isAllowedToEditServiceTypes,
          isAllowedToEditBillingCycles: val.isAllowedToEditBillingCycles,
          isAllowedToCreateEcommerceAreas: val.isAllowedToCreateEcommerceAreas,
          isAllowedToCreateEcommerceBlacklist: val.isAllowedToCreateEcommerceBlacklist,
          isAllowedToCreateEcommerceFees: val.isAllowedToCreateEcommerceFees,
          isAllowedToCreatePromoCodes: val.isAllowedToCreatePromoCodes,
          isAllowedToCreateServiceCodes: val.isAllowedToCreateServiceCodes,
          isAllowedToCreateServiceFrequencies: val.isAllowedToCreateServiceFrequencies,
          isAllowedToCreateServiceTypes: val.isAllowedToCreateServiceTypes,
          isAllowedToCreateBillingCycles: val.isAllowedToCreateBillingCycles,
          isAllowedToDeleteEcommerceAreas: val.isAllowedToDeleteEcommerceAreas,
          isAllowedToDeleteEcommerceBlacklist: val.isAllowedToDeleteEcommerceBlacklist,
          isAllowedToDeleteEcommerceFees: val.isAllowedToDeleteEcommerceFees,
          isAllowedToDeletePromoCodes: val.isAllowedToDeletePromoCodes,
          isAllowedToDeleteServiceCodes: val.isAllowedToDeleteServiceCodes,
          isAllowedToDeleteServiceFrequencies: val.isAllowedToDeleteServiceFrequencies,
          isAllowedToDeleteServiceTypes: val.isAllowedToDeleteServiceTypes,
          isAllowedToDeleteBillingCycles: val.isAllowedToDeleteBillingCycles,
          isAllowedToUseSecureCheckoutLinks: val.isAllowedToUseSecureCheckoutLinks,
          isAllowedToViewAccountEventLogs: val.isAllowedToViewAccountEventLogs,
        });
      }
    ));

  }

  loadAllPermissionGroups() {
    this.allPermissionGroupsLoading = true;

    this.permissionsClient.getAllPermissionGroups().subscribe(
      (response) => {
        this.allPermissionGroups = response.result;
      },
      (error) => {
        this.snackBarService.ShowError(error);
      });
    this.allPermissionGroupsLoading = false;
  }


  loadAllPermissions() {
    this.allPermissionsLoading = true;

    this.permissionsClient.allPermissions().subscribe(
      (response) => {
        this.allPermissions = response.result;
      },
      (error) => {
        this.snackBarService.ShowError(error);
      });

    this.allPermissionsLoading = false;
  }

  loadCurrentPermissionGroups() {
    this.allPermissionsLoading = true;

    this.permissionsClient.getUserPermissionGroups(this.id).subscribe(
      (response) => {
        this.usersCurrentPermissionGroups = response.result;
        this.permissions.clear();
        for (const p of this.usersCurrentPermissionGroups) {
          const gControl = new FormGroup({
            userId: new FormControl(this.id),
            groupId: new FormControl(p.groupId),
            groupName: new FormControl(p.groupName)
          });
          this.permissions.push(gControl);
        }
      },
      (error) => {
        this.snackBarService.ShowError(error);
      });

    this.allPermissionsLoading = false;
  }

  get permissions() {
    return this.userFormGroup.get('permissions') as FormArray;
  }

  getPermissionGroupId = (p: any) => p.id;

  getPermissionGroupName = (p: any) => p.groupName;

  addPermissionGroup() {
    this.changesMade = true;
    const pGroupControl = new FormGroup({
      groupId: new FormControl(0),
      groupName: new FormControl(null),
    });
    this.permissions.insert(0, pGroupControl);
  }

  removePermissionGroup(x) {
    this.changesMade = true;
    this.permissions.removeAt(x);
  }

  loadRoles() {
    this.lookupClient.getAllRoles().subscribe((response) => {
        this.roles = response.result;
      },
      (error) => {
        console.error(error);
        this.snackBarService.ShowError(error);
      });
  }

  loadGroups() {
    this.lookupClient.getAllGroups().subscribe((response) => {
        this.totalGroups = response.result;
      },
      (error) => {
        console.error(error);
        this.snackBarService.ShowError(error);
      });
  }

  loadBusinessUnits() {
    this.lookupClient.getAllBusinessUnits().subscribe((response) => {
        this.totalBusinessUnits = response.result.sort((r1, r2) => this.getBusinessUnitSort(r1, r2));
      },
      (error) => {
        console.error(error);
        this.snackBarService.ShowError(error);
      });
  }

  loadUserDetails() {
    this.userDetailsLoading = true;
    this.subscription.add(
      this.usersClient.getUserDetails(this.id).subscribe((response) => {
          const val = response.result;
          this.userFormGroup.patchValue({
            userId: val.id,
            fullName: val.fullName
          });
        },
        (error) => {
          this.userDetailsLoading = false;
          console.error(error);
          this.snackBarService.ShowError(error);
        }));
    this.userDetailsLoading = false;
  }

  loadUserGroupBUs() {
    this.groupBUsLoading = true;
    this.subscription.add(
      this.usersClient.getUserGroupBusinessUnits(this.id).subscribe((response) => {
          this.groupBUCodes = response.result.userBusinessUnits;
          this.groupBusinessUnits.clear();
          for (const bu of this.groupBUCodes) {
            const buControl = new FormGroup({
              userId: new FormControl(bu.userId),
              businessUnitId: new FormControl(bu.businessUnitId),
              businessUnitName: new FormControl(bu.businessUnitName),
              businessUnitCode: new FormControl(bu.businessUnitCode),
              roleId: new FormControl(bu.roleId),
              roleName: new FormControl(bu.roleName),
              groupId: new FormControl(bu.groupId)
            });
            this.groupBusinessUnits.push(buControl);
          }
        },
        (error) => {
          this.groupBUsLoading = false;
          console.error(error);
          this.snackBarService.ShowError(error);
        }));
    this.groupBUsLoading = false;
  }

  loadIndivdualGroupBUs() {
    this.individualBUsLoading = true;
    this.subscription.add(
      this.usersClient.getUserIndividualBusinessUnits(this.id).subscribe((response) => {
          this.individualBUCodes = response.result.userBusinessUnits.sort((r1, r2) => this.individualBUCodesSort(r1, r2));
          this.businessUnits.clear();
          for (const bu of this.individualBUCodes) {
            const buControl = new FormGroup({
              userId: new FormControl(bu.userId),
              businessUnitId: new FormControl(bu.businessUnitId),
              businessUnitName: new FormControl(bu.businessUnitName),
              businessUnitCode: new FormControl(bu.businessUnitCode),
              roleId: new FormControl(bu.roleId),
              roleName: new FormControl(bu.roleName)
            });
            this.businessUnits.push(buControl);
          }
        },
        (error) => {
          this.individualBUsLoading = false;
          console.error(error);
          this.snackBarService.ShowError(error);
        }));
    this.individualBUsLoading = false;
  }

  loadUserGroups() {
    this.groupsLoading = true;
    this.subscription.add(
      this.usersClient.getUserGroups(this.id).subscribe((response) => {
          this.groups.clear();
          const user = response.result;
          for (const bu of user.groups) {
            const buControl = new FormGroup({
              userId: new FormControl(bu.userId),
              roleId: new FormControl(bu.roleId),
              groupId: new FormControl(bu.groupId),
              groupName: new FormControl(bu.groupName)
            });
            this.groups.insert(0, buControl);
          }
        },
        (error) => {
          this.groupsLoading = false;
          console.error(error);
          this.snackBarService.ShowError(error);
        }));
    this.groupsLoading = false;
  }

  get groups() {
    return this.userFormGroup.get('groups') as FormArray;
  }

  addBusinessUnitGroup() {
    this.changesMade = true;
    const buGroupControl = new FormGroup({
      groupId: new FormControl(0),
      groupName: new FormControl(null),
      roleId: new FormControl(null),
      roleName: new FormControl(null),
    });
    this.groups.insert(0, buGroupControl);
  }

  removeBusinessUnitGroup(x) {
    this.changesMade = true;
    this.groups.removeAt(x);
  }

  getGroupId = (g: GroupDetailsViewModel) => g.id;

  getGroupName = (g: GroupDetailsViewModel) =>
    `${g.groupName} (${g.id})`;

  get businessUnits() {
    return this.userFormGroup.get('businessUnits') as FormArray;
  }

  get groupBusinessUnits() {
    return this.userFormGroup.get('groupBusinessUnits') as FormArray;
  }

  addBusinessUnit() {
    this.changesMade = true;
    const buControl = new FormGroup({
      userId: new FormControl(this.id),
      businessUnitId: new FormControl(null),
      businessUnitName: new FormControl(null),
      businessUnitCode: new FormControl(null),
      roleId: new FormControl(null),
      roleName: new FormControl(null),
    });
    this.businessUnits.insert(0, buControl);
  }

  removeBusinessUnit(x) {
    this.changesMade = true;
    this.businessUnits.removeAt(x);
  }

  getBusinessUnitId = (bu: BusinessUnitViewModel) => bu.id;

  getBusinessUnitName = (bu: BusinessUnitViewModel) =>
    `${bu.name} (${bu.code})`;

  removeGroupBusinessUnit(x) {
    this.changesMade = true;
    this.businessUnits.removeAt(x);
  }

  getGroupBusinessUnitId = (bu: BusinessUnitViewModel) => bu.id;

  getGroupBusinessUnitName = (bu: BusinessUnitViewModel) =>
    `${bu.name} (${bu.code})`;

  private getBusinessUnitSort = (bu1: BusinessUnitViewModel, bu2: BusinessUnitViewModel): number =>
    (bu1.name < bu2.name) ? -1 : ((bu1.name > bu2.name) ? 1 : 0);

  private individualBUCodesSort = (bu1: UserBusinessUnitViewModel, bu2: UserBusinessUnitViewModel): number =>
    (bu1.businessUnitName < bu2.businessUnitName) ? -1 : ((bu1.businessUnitName > bu2.businessUnitName) ? 1 : 0);

  goBack() {
    if (this.changesMade == false || this.savedChanges == true) {
      this.router.navigateByUrl('/admin/users');
    } else {
      if (confirm('Changes were not saved. Do you want to discard changes ?')) {
        this.snackBarService.ShowSuccess('Changes were not saved !');
        this.router.navigateByUrl('/admin/users');
      }
    }
  }

  selectedPermissions() {
    var result = [];

    Object.keys(this.userPermissionFormGroup.controls).forEach((key: string) => {
      const control = this.userPermissionFormGroup.get(key);
      if (control.value && control.value == true) {
        result.push(new UserPermissionViewModel({
          userId: this.id,
          permissionName: key,
          permissionId: this.allPermissions.find(r => r.name.toLowerCase() == key.toLowerCase()).id
        }));
      }
    });

    return result;
  }

  updateUser() {
    const user: UpdateUserCommand = new UpdateUserCommand({
      userId: this.id,
      businessUnits: this.businessUnits.controls.map(
        (x) =>
          new UserBusinessUnitViewModel({
            userId: x.value.userId,
            businessUnitId: x.value.businessUnitId,
            roleId: x.value.roleId,
            businessUnitCode: x.value.businessUnitCode,
            businessUnitName: x.value.businessUnitName,
            roleName: x.value.roleName,
          })
      ),
      groups: this.groups.controls.map(
        (x) =>
          new GroupBusinessUnitViewModel({
            userId: this.id,
            groupId: x.value.groupId,
            roleId: x.value.roleId,
            businessUnitCode: x.value.businessUnitCode,
            businessUnitName: x.value.businessUnitName,
            roleName: x.value.roleName,
          })
      ),
      permissionGroups: this.permissions.controls.map(
        (x) =>
          new GroupPermissionViewModel({
            permissionGroupId: x.value.groupId,
            userId: this.id
          })
      ),
      permissions: this.selectedPermissions()
    });
    this.isLoading = true;

    this.subscription.add(
      this.usersClient.updateUser(user).subscribe(
        (response) => {
          this.isLoading = false;
          this.savedChanges = true;

          if (response.isSuccess) {
            this.snackBarService.ShowSuccess(this.userFormGroup.value.fullName + ' has been updated successfully !');
            // this.router.navigateByUrl("/admin/users");
            window.location.reload();
          }
        },
        (error) => {
          this.isLoading = false;
          this.savedChanges = false;
          console.error(error);
          this.snackBarService.ShowError(error);
        }
      ));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
