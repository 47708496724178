import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { TranslateService } from "@ngx-translate/core";
import { WindowUtils } from "msal";
import { Subscription } from "rxjs";
import { CurrentUserViewModel } from "./apis/customer-care-api.generated";
import { AuthService } from "./shared/auth/auth.service";
import { AppSettings } from "./shared/models/app-settings";
import {
  MENUITEM
} from './app.model'

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {

  private subscription=new Subscription();

  public toggleCSRAdminMenu: boolean = false;
  public toggleMyAccountToolsMenu: boolean = false;
  public toggleReportingMenu: boolean = false;
  public toggleEcommerceMenu: boolean = false;
  public togglePaymentTools: boolean = false;
  

  title = "customer-care-portal";
  currentUser: CurrentUserViewModel = null;

  currentUrl = "";

  isRouteLoading: boolean;

  username: string;

  lang = navigator.language;
  userBrowserIsFrench : boolean = false;
  currentLangIsEnglish : boolean = true;

  public sanitizeImage(base64data): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(base64data);
  }

  onLanguageChangeClicked ()
  {
    if(this.currentLangIsEnglish)
    {
      this.translate.use('fr');
      this.currentLangIsEnglish = false;
    }
    else
    {
      this.translate.use('en');
      this.currentLangIsEnglish = true;
    }
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private msalService: MsalService,
    public translate: TranslateService,
    public settings: AppSettings,
    private location:Location
  ) {
    if (this.lang.substring(0,2) == 'fr')
    {
      this.userBrowserIsFrench = true;
      this.currentLangIsEnglish = false;
      this.translate.use('fr');
    }
    this.translate.use('en');
    // this.httpService.getProfileImage().subscribe(r => {
    //   let reader = new FileReader();
    //   reader.readAsDataURL(r);
    //   reader.onloadend = function() { this.imageData = this.sanitizeImage(reader.result); }.bind(this)
    // });
  }

  ngOnInit() {
    this.currentUrl = this.location.path();


    this.subscription.add(
      this.router.events.subscribe((route) => {
        this.currentUrl = this.location.path();
      })
    );

    if (this.msalService.getAccount()) {
      
      
    this.subscription
      .add(
        this.authService.currentUser
          .subscribe(val => {
            if(val) {
              this.currentUser = val;
              this.username = val.firstName + " " + val.lastName;
            }
          })
      );
    }
  }

  toggleDropDown(menuItem: string) {
   if(menuItem == MENUITEM.CSRPORTALADMIN) {
    this.toggleCSRAdminMenu = !this.toggleCSRAdminMenu;
   } else if(menuItem == MENUITEM.CSRMYACCOUNTTOOLS) {
    this.toggleMyAccountToolsMenu = !this.toggleMyAccountToolsMenu
   } else if(menuItem == MENUITEM.CSRREPORTING) {
    this.toggleReportingMenu = !this.toggleReportingMenu;
  } else if(menuItem == MENUITEM.ECOMMERCE) {
    this.toggleEcommerceMenu = !this.toggleEcommerceMenu;
  } else if(menuItem == MENUITEM.PAYMENTTOOLS){
    this.togglePaymentTools = !this.togglePaymentTools;
  }
}

  ngOnDestroy(){
    this.subscription.unsubscribe();
    this.authService.clearCurrentUser();
  }

  get currentUserIsSystemAdmin() {
    return this.currentUser ? this.currentUser.isSystemAdmin : false;
  }

  get currentUserIsAllowedToImpersonateInMyAccount() {
    return this.currentUser ? this.currentUser.isAllowedToImpersonateInMyAccount : false;
  }

  get currentUserIsAllowedToCreateNotification() {
    return this.currentUser ? this.currentUser.isAllowedToCreateNotification : false;
  }

  get currentUserIsAllowedToViewRequestStats() {
    return this.currentUser ? this.currentUser.isAllowedToViewRequestStats : false;
  }

  get currentUserIsAllowedToViewReports() {
    return this.currentUser ? this.currentUser.isAllowedToViewReports : false;
  }

  get currentUserIsAllowedToEditGroups() {
    return this.currentUser ? this.currentUser.isAllowedToEditGroups : false;
  }

  get currentUserIsAllowedToViewErrorHandling() {
    return this.currentUser ? this.currentUser.isAllowedToViewErrorHandling : false;
  }

  get currentUserIsAllowedToViewUsers() {
    return this.currentUser ? this.currentUser.isAllowedToViewAllUsers || this.currentUser.isSystemAdmin : false;
  }

  get currentUserIsAllowedToCreateUsers() {
    return this.currentUser ? this.currentUser.isAllowedToCreateUser : false;
  }

  get currentUserIsAllowedToViewGroups() {
    return this.currentUser ? this.currentUser.isAllowedToViewGroups : false;
  }

  get currentUserIsAllowedToCreateGroups() {
    return this.currentUser ? this.currentUser.isAllowedToCreateGroups : false;
  }

  get currentUserIsAllowedToViewBusinessUnits() {
    return this.currentUser ? this.currentUser.isAllowedToViewBusinessUnits : false;
  }

  get currentUserIsAllowedToViewFacilities() {
    return this.currentUser ? this.currentUser.isAllowedToViewFacilities: false;
  }

  get currentUserIsAllowedToCreateMyAccountUser() {
    return this.currentUser ? this.currentUser.isAllowedToCreateMyAccountUser : false;
  }

  get currentUserIsAllowedToPayMyBillValidation() {
    return this.currentUser ? this.currentUser.isAllowedToUsePayMyBillValidation : false;
  }

  get currentUserIsAllowedToViewNotifications() {
    return this.currentUser ? this.currentUser.isAllowedToViewNotifications : false;
  }

  get currentUserIsAllowedToEmailLookup() {
    return this.currentUser ? this.currentUser.isAllowedToUseEmailLookup : false;
  }

  get currentUserIsAllowedToTransferOwnership() {
    return this.currentUser ? this.currentUser.isAllowedToTransferOwnership : false;
  }
  
  get currentUserIsAllowedToDeleteAccount() {
    return this.currentUser ? this.currentUser.isAllowedToDeleteMyAccountProfile : false;
  }

  get currentUserIsAllowedToMassTransferOwnership() {
    return this.currentUser ? this.currentUser.isAllowedToMassTransferOwnership : false;
  }

  get currentUserIsAllowedToCreateManaged() {
    return this.currentUser ? this.currentUser.isAllowedToCreateManagedAutoPayMyAccountUser : false;
  }

  get currentUserIsAllowedToSearchAccount() {
    return this.currentUser ? this.currentUser.isAllowedToUseAccountLookup : false;
  }

  get currentUserIsAllowedToDeleteProfile() {
    return this.currentUser ? this.currentUser.isAllowedToDeleteAzureProfile : false;
  }

  get currentUserIsAllowedToRecordSync() {
    return this.currentUser ? this.currentUser.isAllowedToUseRecordSync : false;
  }

  get currentUserIsAllowedToDeleteEbilling() {
    return this.currentUser ? this.currentUser.isAllowedToDeleteEbilling : false;
  }

  get currentUserIsAllowedToViewCustomerFinder() {
    return this.currentUser ? this.currentUser.isAllowedToUseCustomerFinder : false;
  }

  get currentUserIsAllowedToDeleteFusionAutoPay() {
    return this.currentUser ? this.currentUser.isAllowedToDeleteFusionAutoPay : false;
  }

  get currentUserIsAllowedToViewPermissionGroups()
  {
    return this.currentUser ? this.currentUser.isAllowedToViewAllPermissionGroups : false;
  }

  get currentUserIsAllowedToViewEcommerceAreas()
  {
    return this.currentUser ? this.currentUser.isAllowedToViewEcommerceAreas : false;
  }

  get currentUserIsAllowedToViewEcommerceBlacklist()
  {
    return this.currentUser ? this.currentUser.isAllowedToViewEcommerceBlacklist : false;
  }

  get currentUserIsAllowedToViewEcommerceFees()
  {
    return this.currentUser ? this.currentUser.isAllowedToViewEcommerceFees : false;
  }

  get currentUserIsAllowedToViewPromoCodes()
  {
    return this.currentUser ? this.currentUser.isAllowedToViewPromoCodes : false;
  }

  get currentUserIsAllowedToViewServiceCodes()
  {
    return this.currentUser ? this.currentUser.isAllowedToViewServiceCodes : false;
  }

  get currentUserIsAllowedToViewServiceFrequencies()
  {
    return this.currentUser ? this.currentUser.isAllowedToViewServiceFrequencies : false;
  }

  get currentUserIsAllowedToViewServiceTypes()
  {
    return this.currentUser ? this.currentUser.isAllowedToViewServiceTypes : false;
  }

  get currentUserIsAllowedToViewBillingCycles()
  {
    return this.currentUser ? this.currentUser.isAllowedToViewBillingCycles : false;
  }
  
  get currentUserIsAllowedToEditEcommerceAreas()
  {
    return this.currentUser ? this.currentUser.isAllowedToEditEcommerceAreas : false;
  }

  get currentUserIsAllowedToEditEcommerceBlacklist()
  {
    return this.currentUser ? this.currentUser.isAllowedToEditEcommerceBlacklist : false;
  }

  get currentUserIsAllowedToEditEcommerceFees()
  {
    return this.currentUser ? this.currentUser.isAllowedToEditEcommerceFees : false;
  }

  get currentUserIsAllowedToEditPromoCodes()
  {
    return this.currentUser ? this.currentUser.isAllowedToEditPromoCodes : false;
  }

  get currentUserIsAllowedToEditServiceCodes()
  {
    return this.currentUser ? this.currentUser.isAllowedToEditServiceCodes : false;
  }

  get currentUserIsAllowedToEditServiceFrequencies()
  {
    return this.currentUser ? this.currentUser.isAllowedToEditServiceFrequencies : false;
  }

  get currentUserIsAllowedToEditServiceTypes()
  {
    return this.currentUser ? this.currentUser.isAllowedToEditServiceTypes : false;
  }

  get currentUserIsAllowedToEditBillingCycles()
  {
    return this.currentUser ? this.currentUser.isAllowedToEditBillingCycles : false;
  }

  get currentUserIsAllowedToDeleteEcommerceAreas()
  {
    return this.currentUser ? this.currentUser.isAllowedToDeleteEcommerceAreas : false;
  }

  get currentUserIsAllowedToDeleteEcommerceBlacklist()
  {
    return this.currentUser ? this.currentUser.isAllowedToDeleteEcommerceBlacklist : false;
  }

  get currentUserIsAllowedToDeleteEcommerceFees()
  {
    return this.currentUser ? this.currentUser.isAllowedToDeleteEcommerceFees : false;
  }

  get currentUserIsAllowedToDeletePromoCodes()
  {
    return this.currentUser ? this.currentUser.isAllowedToDeletePromoCodes : false;
  }
  get currentUserIsAllowedToDeleteServiceCodes()
  {
    return this.currentUser ? this.currentUser.isAllowedToDeleteServiceCodes : false;
  }

  get currentUserIsAllowedToDeleteServiceFrequencies()
  {
    return this.currentUser ? this.currentUser.isAllowedToDeleteServiceFrequencies : false;
  }

  get currentUserIsAllowedToViewPaymentTools() {
    return this.currentUser ? this.currentUser.isAllowedToUseSecureCheckoutLinks : false;
  }

  get currentUserIsAllowedToViewAccountEventLogs() {
    return this.currentUser ? this.currentUser.isAllowedToViewAccountEventLogs : false;
  }

  get currentUserIsAllowedToDeleteServiceTypes()
  {
    return this.currentUser ? this.currentUser.isAllowedToDeleteServiceTypes : false;
  }

  get currentUserIsAllowedToDeleteBillingCycles()
  {
    return this.currentUser ? this.currentUser.isAllowedToDeleteBillingCycles : false;
  }
  
  get currentUserIsAllowedToCreateEcommerceAreas()
  {
    return this.currentUser ? this.currentUser.isAllowedToCreateEcommerceAreas : false;
  }

  get currentUserIsAllowedToCreateEcommerceBlacklist()
  {
    return this.currentUser ? this.currentUser.isAllowedToCreateEcommerceBlacklist : false;
  }

  get currentUserIsAllowedToCreateEcommerceFees()
  {
    return this.currentUser ? this.currentUser.isAllowedToCreateEcommerceFees : false;
  }

  get currentUserIsAllowedToCreatePromoCodes()
  {
    return this.currentUser ? this.currentUser.isAllowedToCreatePromoCodes : false;
  }

  get currentUserIsAllowedToCreateServiceCodes()
  {
    return this.currentUser ? this.currentUser.isAllowedToCreateServiceCodes : false;
  }

  get currentUserIsAllowedToCreateServiceFrequencies()
  {
    return this.currentUser ? this.currentUser.isAllowedToCreateServiceFrequencies : false;
  }

  get currentUserIsAllowedToCreateServiceTypes()
  {
    return this.currentUser ? this.currentUser.isAllowedToCreateServiceTypes : false;
  }

  get currentUserIsAllowedToCreateBillingCycles()
  {
    return this.currentUser ? this.currentUser.isAllowedToCreateBillingCycles : false;
  }

  get myAccountActive() {
    return this.currentUrl ? this.currentUrl.includes("my-account") : false;
  }

  get paymentToolsActive() {
    return this.currentUrl ? this.currentUrl.includes("payment-link") : false;
  }

  get adminActive() {
    return this.currentUrl ? this.currentUrl.includes("admin") : false;
  }

  get reportingActive() {
    return this.currentUrl ? this.currentUrl.includes("reports") : false;
  }

  get ecommerceActive() {
    return this.currentUrl ? this.currentUrl.includes("ecommerce") : false;
  }

  onSignoutClicked() {
    this.authService.clearLocalStorage();
    this.msalService.logout();
  }

  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some((h) =>
    h.test(window.location.host)
  );
}