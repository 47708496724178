import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AssignToUserViewModel, ReportsClient, ResendPaymentEmailCommand, UsersClient } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';


export interface IResendEmailDialogInputData {
  eventId:number
}

@Component({
  selector: 'app-resend-email-dialog',
  templateUrl: './resend-email-dialog.component.html',
  styleUrls: ['./resend-email-dialog.component.scss']
})



export class ResendEmailDialogComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();


  filterFormGroup = new FormGroup({
    emailFormControl: new FormControl(null),
  });

  isLoading = false;


  constructor(private snackBarService: SnackBarService, private dialog: MatDialogRef<ResendEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IResendEmailDialogInputData,
    private reportClient: ReportsClient
  ) { }

  ngOnInit() {

  }

  onEmailClicked()
  {
    this.isLoading = true;

    const request: ResendPaymentEmailCommand = new ResendPaymentEmailCommand(
      {
          eventLogId: this.data.eventId,
          email: this.filterFormGroup.get('emailFormControl').value
      }
  );

    this.subscription.add(
      this.reportClient
        .resendPaymentEmailCommand(
          request
        )
        .subscribe(
          (result) => {
            if(result.isSuccess)
            {
              this.isLoading = false;
              this.snackBarService.ShowSuccess("Email Confirmation Resent Successfully");
              this.dialog.close();
            }
            else
            {
              this.isLoading = false;
              this.snackBarService.ShowError(result.errorMessage);
            }
          },
          (error) => {
            console.error(error);
            this.isLoading = false;
            this.snackBarService.ShowError(error);
          }
        )
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
